<template>
	<v-expansion-panels multiple class="accordion">
		<v-expansion-panel v-for="(questions, i) in faq" :key="i" class="accordion__panel">
			<v-expansion-panel-header class="accordion__header">
				{{ questions.question }}
				<template v-slot:actions>
					<v-icon color="primary">
						$vuetify.icons.dropdown
					</v-icon>
				</template>
			</v-expansion-panel-header>
			<v-expansion-panel-content class="accordion__content">
				<div v-html="questions.answer" />
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {
	name: 'Accordion',
	props: {
		faq: {
			type: Array,
			required: true
		}
	}
};
</script>

<style scoped lang="scss">
.accordion {
	&__panel {
		&:before {
			box-shadow: none;
		}
	}
	&__header {
		background-color: $grey-lighter;
		border-top: 2px solid $grey-lightest;
		font-size: $font-size-root;
		font-weight: map-get($font-weights, 'regular');
	}
	&__content {
		background-color: #f0f0f0;
	}
}
</style>
